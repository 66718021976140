<template>
  <div v-if="!items">
    <div class="mb-1 text-right">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="addMandat()"
      >
        <feather-icon
          icon="PlusSquareIcon"
          class="mr-1"
        />
        <span class="align-middle">Neues Mandat</span>
      </b-button>
    </div>
  </div>
  <div v-else>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col md="2">
            Alle Mandate
          </b-col>
          <b-col>
            <b-form-checkbox
              v-model="alleMandate"
              class="custom-control-primary"
              name="check-button"
              switch
              @change="fetch"
            />
          </b-col>
          <b-col>
            <div class="mb-1 text-right">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="addMandat()"
              >
                <feather-icon
                  icon="PlusSquareIcon"
                  class="mr-1"
                />
                <span class="align-middle">Neues Mandat</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <div>
          <b-form-input
            id="filterInput"
            v-model="filter"
            class="d-inline-block mr-1"
            placeholder="Suchen..."
          />
        </div>
      </b-card-body>

      <b-table
        ref="tblMandat"
        selectable
        select-mode="single"
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        primary-key="DosID"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-compare="compDate"
        @filtered="onFiltered"
        selected-variant="primary"
        @row-selected="onRowSelected"
      />

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Pro Seite"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BFormGroup, BFormSelect, BPagination, BFormInput, BCardBody, BButton, BFormCheckbox, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import dos from '@/service/dossier'
import ben from '@/service/benutzer'

export default {
  components: {
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BCardBody,
    BButton,
    BFormCheckbox,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      alleMandate: true,
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'Bezeichnung', label: 'Bezeichnung', sortable: true },
        { key: 'Erstellt', label: 'Erstellt', sortable: true },
        { key: 'BenutzerID', label: 'Benutzer', sortable: true },
      ],
      /* eslint-disable global-require */
      items: null,
      selected: [],
    }
  },
  async mounted() {
    this.$root.$refs.Mandat = this // Register to reload data after adding new mandat...
    this.fetch()
    window.checkSave = null
  },
  methods: {
    async fetch() {
      // Admin gets all dossiers, Mandant gets all dossier of its Berater and himself or only his own, Berater gets its own dossiers, Klient gets its own dossier...
      const r = await dos.getDossiers((this.$g.user.Rolle === 'admin' ? 0 : this.$g.user.MandantID), (this.$g.user.Rolle === 'admin' || (this.$g.user.Rolle === 'mandant' && this.alleMandate) ? 0 : this.$g.user.BenID))
      if (r.data.length > 0) {
        this.items = r.data
        this.totalRows = this.items.length
        // const row = this.items.findIndex(x => x.DosID === this.$root.currDosID)
        // console.log(row)
        // this.selectedItem = row
        // this.selectedRow = this.$root.currDosID

        // let i = 0
        // for (i = 0; i < this.items.length && this.items[i].DosID !== this.$root.currDosID; i += 1) ;
        // if (this.$refs.tblMandat !== null && this.$refs.tblMandat.selectRow !== null) this.$refs.tblMandat.selectRow(i)
      }
      window.checkSave = null
    },
    async addMandat() {
      this.$root.$refs.start.tabIndex = 1
      this.$root.$refs.MandatInfo.initializeNewMandat()
    },
    async onRowSelected(items) {
      if (items.length === 0) return
      this.$root.currDosID = items.length === 1 ? items[0].DosID : 0
      this.$root.currDosArt = items.length === 1 ? items[0].Art : 0
      this.$root.currDosBez = items.length === 1 ? (items[0].Art === 0 ? 'Trennung ' : items[0].Art === 1 ? 'Scheidung ' : 'Elternvereinbarung ') + items[0].Bezeichnung : '' // eslint-disable-line
      this.$root.currMandantID = items.length === 1 ? items[0].MandatID : 0
      this.$root.MitKind = items.length === 1 ? items[0].MitKind : false
      // this.$root.$refs.Grunddaten.showKidTab = this.$root.MitKind
      const r = await ben.changeCurrDosID(this.$g.user.BenID, this.$root.currDosID) // eslint-disable-line
      this.$root.$refs.WF.refetch()
      this.$root.$refs.MandatInfo.refetch()
      const a = await ben.getBenutzerAbilities(this.$g.user.role, this.$root.currDosID)
      if (a.data !== null) {
        const ud = JSON.parse(localStorage.getItem('userData'))
        ud.Ability = a.data
        localStorage.setItem('userData', JSON.stringify(ud))
        this.$ability.update(JSON.parse(ud.Ability))
      }
      window.checkSave = null
    },
    openActiveUser() {
      this.isBenutzerEditSidebarActive = true
      this.$g.manUserEdit = true
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    compDate(itemA, itemB, key) {
      if (key !== 'Erstellt') return false
      return itemA.Created - itemB.Created
    },
    async refetchAfterAdd() { // Called from MandatInfo after adding new mandat..
      const r = await dos.getDossiers((this.$g.user.Rolle === 'admin' ? 0 : this.$g.user.MandantID), (this.$g.user.Rolle === 'admin' || (this.$g.user.Rolle === 'mandant' && this.alleMandate) ? 0 : this.$g.user.BenID))
      if (r.data.length > 0) {
        this.items = r.data
        this.totalRows = this.items.length
        if (this.$refs.tblMandat !== null && this.$refs.tblMandat.selectRow !== null) this.$refs.tblMandat.selectRow(0)
        this.$root.currDosArt = this.items[0].Art
        this.$root.currDosBez = this.$root.currDosArt === 0 ? 'Trennung ' : this.$root.currDosArt === 1 ? 'Scheidung ' : 'Elternvereinbarung ' + this.items[0].Bezeichnung // eslint-disable-line
        // l8.2.24: Maybe those two properties should also be set after adding new dossier => check later...
        // this.$root.currMandantID = items.length === 1 ? items[0].MandatID : 0
        // this.$root.MitKind = items.length === 1 ? items[0].MitKind : false
        this.$root.$refs.WF.refetch()
      }
    },
  },
}
</script>
