<template>
  <div style="height: inherit">
    <div class="todo-app-list">
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="addTask()"
        >
          <feather-icon
            icon="PlusSquareIcon"
            class="mr-50"
          />
          <span class="align-middle">Neue Aufgabe</span>
        </b-button>
      </div>
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <draggable
          v-model="tasks"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list"
          :move="onMove"
        >
          <li
            v-for="task in tasks"
            :key="task.TaskID"
            class="todo-item"
            :class="{ 'completed': task.IstErledigt }"
            @click="handleTaskClick(task)"
            @drop="handleTaskDrop(task)"
          >
            <feather-icon
              icon="MoreVerticalIcon"
              class="draggable-task-handle d-inline"
            />
            <div class="todo-title-wrapper">
              <div class="todo-title-area">
                <div class="title-wrapper">
                  <b-form-checkbox
                    :checked="task.IstErledigt===1"
                    @click.native.stop
                    @change="setTaskCompleted(task)"
                  />
                  <span class="todo-title">{{ task.Titel }}</span>
                </div>
              </div>
              <div class="todo-item-action">
                <small class="text-nowrap text-muted mr-1">{{ task.ErledigenBisFmt }}</small>
                <div
                  v-show="task.Wichtig"
                  class="badge-wrapper mr-1"
                >
                  <b-badge
                    variant="light-danger"
                    class="text-capitalize"
                  >
                    {{ task.Wichtig ? "wichtig" : "" }}
                  </b-badge>
                </div>
              </div>
            </div>
          </li>
        </draggable>
        <div
          class="no-results"
          :class="{'show': !tasks.length}"
        >
          <h5>Keine Aktivitäten</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>
    <workflow-edit
      :is-task-edit-sidebar-active.sync="isTaskEditSidebarActive"
      @refetch-workflow="refetch"
    />
  </div>
</template>

<script>
import {
  BButton, BFormCheckbox, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import dos from '@/service/dossier'
import WorkflowEdit from './WorkflowEdit.vue'

export default {
  components: {
    BButton,
    BFormCheckbox,
    BBadge,
    draggable,
    VuePerfectScrollbar,

    WorkflowEdit,
  },
  directives: {
    Ripple,
  },
  setup() {
    const isTaskEditSidebarActive = ref(false)
    const perfectScrollbarSettings = {
      minScrollbarLength: 20,
      maxScrollbarLength: 60,
      suppressScrollX: true,
      suppressScrollY: false,
      wheelPropagation: true,
      scrollYMarginOffset: 10,
    }
    const tasks = ref([])

    return {
      tasks,
      isTaskEditSidebarActive,
      perfectScrollbarSettings,
    }
  },
  data() {
    this.$g.currTaskID = 5 // WARNING: If not set to 1, sidePage is not shown!!!
    this.$g.manTaskEdit = false
    return {
      // tasks: [],
      taskOldIx: -1,
      taskNewIx: -1,
    }
  },
  async mounted() {
    const r = await dos.getDossierWorkflow(this.$root.currDosID)
    this.tasks = r.data !== null ? r.data : { }
    this.$g.currTaskID = r.data.length > 0 ? r.data[0].TaskID : 0
    this.$root.$refs.WF = this // "Hack" to reload data when changing dossier in Mandant-view...
  },
  methods: {
    async addTask() {
      this.$g.manTaskEdit = false
      this.$root.$refs.WFT.createTask()
      this.isTaskEditSidebarActive = true
    },
    onMove({ relatedContext, draggedContext }) {
      this.taskOldIx = relatedContext.index // Hack because of lint-error not used...
      this.taskOldIx = draggedContext.index
      this.taskNewIx = draggedContext.futureIndex
    },
    async handleTaskClick(task) {
      this.$g.currTaskID = task.TaskID
      this.isTaskEditSidebarActive = true
      // this.$root.$refs.WFT.isTaskEditSidebarActive = true
      // this.$emit('update:is-task-edit-sidebar-active', true)
    },
    async handleTaskDrop(task) {
      await dos.putDossierWorkflowSeq(task.DosID, this.taskOldIx + 1, this.taskNewIx + 1)
    },
    async setTaskCompleted(task) {
      await dos.putDossierWorkflowTaskCompleted(task.TaskID)
      for (let i = 0; i < this.tasks.length; i += 1) if (this.tasks[i].TaskID === task.TaskID) this.tasks[i].IstErledigt = !this.tasks[i].IstErledigt
      this.$root.$refs.WFT.fetch()
    },
    async refetch() {
      const r = await dos.getDossierWorkflow(this.$root.currDosID)
      this.tasks = r.data !== null ? r.data : { }
      this.$g.currTaskID = r.data.length > 0 ? r.data[0].TaskID : 0
    },
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;

    .todo-task-list .todo-item:hover & {
      visibility: visible;
    }
}
</style>

<style lang="scss">
.scroll-area {
  position: relative;
  margin: auto;
  width: inherit;
  height: inherit;
}
</style>

<style lang="scss">
@import "@core/scss/base/pages/app-todo.scss";
</style>
