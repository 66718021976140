<template>
  <b-tabs
    v-model="tabIndex"
    pills
  >

    <b-tab>
      <template #title>
        <feather-icon
          icon="FolderIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Mandate</span>
      </template>
      <mandat
        class="mt-2 pt-75"
      />
    </b-tab>

    <b-tab button-id="tabMandatInfo">
      <template #title>
        <feather-icon
          icon="ClipboardIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Mandat-Info</span>
      </template>
      <mandatDetail class="mt-2 pt-75" />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="CheckSquareIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Workflow</span>
      </template>
      <workflow class="mt-2 pt-75" />
    </b-tab>

  </b-tabs>
</template>

<script>
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import Mandat from './Mandat.vue'
import MandatDetail from './MandatDetail.vue'
import Workflow from './Workflow.vue'

export default {
  components: {
    BTab,
    BTabs,

    Mandat,
    MandatDetail,
    Workflow,
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
  async mounted() {
    this.$root.$refs.start = this // Register to set active tab...
    window.checkSave = null
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
