<template>
  <div v-if="!task">Loading...
  </div>
  <div v-else>
    <b-sidebar
      id="task-sidebar"
      :visible="isTaskEditSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-task-edit-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ $g.manTaskEdit ? 'Aktivität ändern' : 'Neue Aktivität erfassen' }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <!-- BODY -->
        <validation-observer
          #default="{ invalid }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="save"
          >
            <validation-provider
              #default="validationContext"
              name="Aktivitaet"
              rules="required"
            >
              <b-form-group
                label="Aktivität"
                label-for="Aktivitaet"
              >
                <b-form-input
                  id="Aktivitaet"
                  v-model="task.Titel"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Aktivität"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group
              label="Beschreibung"
              label-for="Beschreibung"
            >
              <quill-editor
                id="quil-content"
                v-model="task.Beschrieb"
                :options="editorOption"
                class="border-bottom-0"
              />
              <div
                id="quill-toolbar"
                class="d-flex justify-content-end border-top-0"
              >
                <!-- Add a bold button -->
                <button class="ql-bold" />
                <button class="ql-italic" />
                <button class="ql-underline" />
                <button class="ql-align" />
                <button class="ql-link" />
              </div>
            </b-form-group>

            <b-form-group
              label="Erledigen bis"
              label-for="Erledigen"
            >
              <flat-pickr
                v-model="task.ErledigenBis"
                :config="config"
                class="form-control"
              />
            </b-form-group>

            <b-form-group
              label="Wer"
              label-for="Wer"
            >
              <b-form-input
                id="Wer"
                v-model="task.Wer"
                trim
                placeholder="Wer"
              />
            </b-form-group>

            <b-form-group
              label="Wichtig"
              label-for="Wichtig"
            >
              <b-form-checkbox
                id="Wichtig"
                v-model="task.Wichtig"
              />
            </b-form-group>

            <b-form-group
              label="Erledigt am"
              label-for="Erledigt"
            >
              <flat-pickr
                v-model="task.Erledigt"
                :config="config"
                class="form-control"
              />
            </b-form-group>

            <b-form-group
              label="Bemerkung"
              label-for="Bemerkung"
            >
              <b-form-textarea
                id="Bemerkung"
                v-model="task.Bem"
                placeholder="Bemerkung"
                rows="2"
                max-rows="4"
              />
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                type="submit"
                :disabled="invalid"
              >
                Speichern
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                class="mr-1"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Abbrechen
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="delTask"
              >
                Löschen
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import dos from '@/service/dossier'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import { quillEditor } from 'vue-quill-editor'
import { German } from 'flatpickr/dist/l10n/de'
import { French } from 'flatpickr/dist/l10n/fr'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormInvalidFeedback,
    BButton,
    flatPickr,
    quillEditor,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTaskEditSidebarActive',
    event: 'update:is-task-edit-sidebar-active',
  },
  props: {
    isTaskEditSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    this.currTaskIDChg = this.$g.currTaskID
    this.$g.manTaskEdit = true
    return {
      isTaskEditSidebarActiveX: false,
      required,
      alphaNum,
      email,
      task: null,
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'j M, Y',
        altInput: true,
        // dateFormat: 'd.m.Y',
        locale: this.$g.user.Sprache === 'fr' ? French : German,
      },
    }
  },
  async mounted() { // 1st loading...
    // const r = await dos.getDossierWorkflowTask(this.$g.currTaskID === null ? 1 : this.$g.currTaskID)
    // if (r !== null && r.data.length === 1) this.task = r.data[0]
    this.fetch()
    this.$root.$refs.WFT = this // "Hack" to access this view in the Workflow-view...
  },
  async updated() { // current task must be saved and merely be loaded from DB when necessary, otherwise the hook is permanently called, thus IIS-Pool is stopped!
    if ((this.currTaskIDChg !== this.$g.currTaskID)) { // TaskID changed from Mandat...
      this.currTaskIDChg = this.$g.currTaskID
      this.fetch()
    }
  },
  setup(props, { emit }) {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Aktivitätsbeschreibung',
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(emit)

    return {
      editorOption,
      // onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    async fetch() {
      const r = await dos.getDossierWorkflowTask(this.$g.currTaskID)
      this.task = r.data !== null ? r.data[0] : { }
    },
    async createTask() { // called from workflow.vue
      if (!this.$g.manTaskEdit) {
        if (this.task === undefined) {
          const r = await dos.getDossierWorkflowTask(5)
          this.task = r.data !== null ? r.data[0] : { }
        }
        this.task.DosID = this.$root.currDosID
        // this.task.Seq = this.task.Seq => already set...
        this.task.Titel = ''
        this.task.Beschrieb = ' '
        this.task.Bem = ''
        this.task.Wer = ''
        this.task.Wichtig = 0
        this.task.ErledigenBis = null
        this.task.Erledigt = null
      }
    },
    async save() {
      try {
        if (this.$g.manTaskEdit) {
          await dos.putDossierWorkflowTask(this.task)
          this.$emit('update:is-task-edit-sidebar-active', false)
          this.$emit('refetch-workflow')
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Aktivität geändert',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Aktivität erfolgreich geändert!',
              },
            },
            { position: 'bottom-right' },
          )
        } else {
          await dos.putDossierWorkflowTaskNew(this.task)
          this.$emit('update:is-task-edit-sidebar-active', false)
          this.$emit('refetch-workflow')
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Aktivität hinzugefügt',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Neue Aktivität erfolgreich hinzugefügt!',
              },
            },
            { position: 'bottom-right' },
          )
        }
        this.$g.manTaskEdit = true
      } catch (error) {
        // console.log(error)
      }
    },
    async delTask() {
      const ok = await this.$root.msgBoxConfirm('Soll die Aufgabe gelöscht werden?')
      if (ok) {
        await dos.putDossierWorkflowTaskDel(this.task.TaskID)
        this.$emit('update:is-task-edit-sidebar-active', false)
        this.$emit('refetch-workflow')
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Aktivität gelöscht',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Aktivität erfolgreich gelöscht!',
            },
          },
          { position: 'bottom-right' },
        )
        this.$g.manTaskEdit = true
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
