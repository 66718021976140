<template>
  <div v-if="!data">Loading...
  </div>
  <div v-else>
    <form-wizard
      ref="wizard"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      back-button-text="Zurück"
      next-button-text="Weiter"
      finish-button-text="Speichern"
      class="mb-1"
      @on-complete="formSubmitted"
    >
      <tab-content
        title="Klient:in"
        :before-change="validationKlientHonorar"
      >
        <validation-observer
          ref="klientHonorarRules"
          tag="form"
        >
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Anrede"
                label-for="anrede"
              >
                <v-select
                  v-model="data.AnredeF"
                  :options="anrede"
                  :reduce="val => val.value"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Vorname"
                label-for="vorname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="vorname"
                  rules="required"
                >
                  <b-form-input
                    id="vorname"
                    v-model="data.VornameF"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small
                    v-show="errors.length"
                    class="text-danger"
                  >{{ $g.browserLocale === 'fr' ? 'Prénom manque!' : 'Vorname fehlt!' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Name"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="data.NameF"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small
                    v-show="errors.length"
                    class="text-danger"
                  >{{ $g.browserLocale === 'fr' ? 'Name manque!' : 'Name fehlt!' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <b-form-group
                label="Postleitzahl"
                label-for="plz"
              >
                <validation-provider
                  #default="{ errors }"
                  name="plz"
                  rules="required|integer"
                >
                  <b-form-input
                    id="plz"
                    v-model="data.PlzF"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small
                    v-show="errors.length"
                    class="text-danger"
                  >{{ $g.browserLocale === 'fr' ? 'Code postal manque ou ne pas numeric!' : 'Postleitzahl fehlt oder nicht numerisch!' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Ort"
                label-for="ort"
              >
                <validation-provider
                  #default="{ errors }"
                  name="ort"
                  rules="required"
                >
                  <b-form-input
                    id="ort"
                    v-model="data.OrtF"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small
                    v-show="errors.length"
                    class="text-danger"
                  >{{ $g.browserLocale === 'fr' ? 'Lieu manque!' : 'Ort fehlt!' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Strasse / Nr."
                label-for="strasse"
              >
                <validation-provider
                  #default="{ errors }"
                  name="strasse"
                  rules="required"
                >
                  <b-form-input
                    id="strasse"
                    v-model="data.StrasseNrF"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small
                    v-show="errors.length"
                    class="text-danger"
                  >{{ $g.browserLocale === 'fr' ? 'Rue / nr. manque!' : 'Strasse / Nr. fehlt!' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <b-form-group
                label="E-Mail"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  :rules="data.MitZugriffF === true ? 'required|email' : 'email'"
                >
                  <b-form-input
                    id="email"
                    v-model="data.EMailF"
                    type="email"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small
                    v-show="errors.length"
                    class="text-danger"
                  >{{ $g.browserLocale === 'fr' ? 'Courriel manque ou pas valid!' : 'Email leer oder ungültig!' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Telefon"
                label-for="telefon"
              >
                <b-form-input
                  v-model="data.TelefonF"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              class="text-center"
            >
              <b-form-group
                label="Zugriff auf Belege"
                label-for="zugriff"
              >
                <b-form-checkbox
                  v-model="data.MitZugriffF"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                  @change="setMitZugriff"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Anmerkung
              </h5>
              <small class="text-muted">Wird der Zugriff auf die Belege für das Hochladen freigegeben, erhält der Klient beim Speichern dieser Daten eine E-Mail mit den entsprechenden Zugangsinformationen.</small>
            </b-col>
          </b-row>

        </validation-observer>
      </tab-content>

      <tab-content
        title="Klient:in Partner:in"
        :before-change="validationKlientPartner"
      >
        <validation-observer
          ref="klientPartnerRules"
          tag="form"
        >
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Anrede"
                label-for="anrede"
              >
                <v-select
                  v-model="data.AnredeM"
                  :options="anrede"
                  :reduce="val => val.value"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Vorname"
                label-for="vorname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="vorname"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.VornameM"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small
                    v-show="errors.length"
                    class="text-danger"
                  >{{ $g.browserLocale === 'fr' ? 'Prénom manque!' : 'Vorname fehlt!' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Name"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.NameM"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small
                    v-show="errors.length"
                    class="text-danger"
                  >{{ $g.browserLocale === 'fr' ? 'Name manque!' : 'Name fehlt!' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <b-form-group
                label="Postleitzahl"
                label-for="plz"
              >
                <validation-provider
                  #default="{ errors }"
                  name="plz"
                  rules="required|integer"
                >
                  <b-form-input
                    v-model="data.PlzM"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small
                    v-show="errors.length"
                    class="text-danger"
                  >{{ $g.browserLocale === 'fr' ? 'Code postal manque ou ne pas numeric!' : 'Postleitzahl fehlt oder nicht numerisch!' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Ort"
                label-for="ort"
              >
                <validation-provider
                  #default="{ errors }"
                  name="ort"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.OrtM"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small
                    v-show="errors.length"
                    class="text-danger"
                  >{{ $g.browserLocale === 'fr' ? 'Lieu manque!' : 'Ort fehlt!' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Strasse / Nr."
                label-for="strasse"
              >
                <validation-provider
                  #default="{ errors }"
                  name="strasse"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.StrasseNrM"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small
                    v-show="errors.length"
                    class="text-danger"
                  >{{ $g.browserLocale === 'fr' ? 'Rue / nr. manque!' : 'Strasse / Nr. fehlt!' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <b-form-group
                label="E-Mail"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  :rules="data.MitZugriffM === true ? 'required|email' : 'email'"
                >
                  <b-form-input
                    v-model="data.EMailM"
                    type="email"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small
                    v-show="errors.length"
                    class="text-danger"
                  >{{ $g.browserLocale === 'fr' ? 'Courriel manque ou pas valid!' : 'Email leer oder ungültig!' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Telefon"
                label-for="telefon"
              >
                <b-form-input
                  v-model="data.TelefonM"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              class="text-center"
            >
              <b-form-group
                label="Zugriff auf Belege"
                label-for="zugriff"
              >
                <b-form-checkbox
                  v-model="data.MitZugriffM"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                  @change="setMitZugriff"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Anmerkung
              </h5>
              <small class="text-muted">Wird der Zugriff auf die Belege für das Hochladen freigegeben, erhält der Klient beim Speichern dieser Daten eine E-Mail mit den entsprechenden Zugangsinformationen.</small>
            </b-col>

          </b-row>
        </validation-observer>
      </tab-content>

      <tab-content
        title="Mandat"
      >
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Mandats-Art"
              label-for="art"
            >
              <v-select
                id="art"
                v-model="data.Art"
                :options="art"
                :reduce="val => val.value"
                :clearable="false"
                @input="setMandat"
              />
            </b-form-group>
          </b-col>
          <b-col v-show="data.Art !== 2" md="4">
            <b-form-group
              label="Güterstand"
              label-for="gueterstand"
            >
              <v-select
                v-model="data.Gueterstand"
                :disabled="data.Art===2"
                :options="gueterstand"
                :reduce="val => val.value"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="2"
            class="text-center"
          >
            <b-form-group
              label="Gibt es einen Vertrag"
              label-for="vertrag"
            >
              <b-form-checkbox
                v-model="data.HatVertrag"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>
          <b-col
            md="2"
            class="text-center"
          >
            <b-form-group
              label="Rechnung an KlientIn"
              label-for="honorar"
            >
              <b-form-checkbox
                v-model="data.RechnungKlient"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col v-show="data.Art === 2" md="4">
          </b-col>
          <b-col v-show="data.Art !== 2" md="4">
            <b-form-group
              :label="(data.Art === 0 ? 'Datum Trennungseinleitung' : 'Datum Scheidungseinleitung') + ' (Versand Brief an Gericht)'"
              label-for="Scheidungseinleitung"
            >
              <flat-pickr
                v-model="data.Scheidungseinleitung"
                :config="config"
                class="form-control"
              />
            </b-form-group>
            <b-form-group
              label="Benutzer"
              label-for="benutzer"
            >
              <v-select
                v-model="data.BenID"
                :options="benutzer"
                :reduce="val => val.value"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col md="8">
            <b-form-group
              label="Bemerkung"
              label-for="bemerkung"
            >
              <b-form-textarea
                v-model="data.Bemerkung"
                rows="4"
                max-rows="4"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="4">
            <b-overlay :show="isRun" no-wrap></b-overlay>
            <b-button
              variant="primary"
              :disabled = "data.DosID === 0"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="getVollmacht"
            >
              Vollmacht erstellen
            </b-button>
          </b-col>

          <div v-show="false">
            <word-editor
              ref="we"
              dateiName="Vollmacht"
              modal-i-d="vollmacht"
              :doc-in="docIn"
              :modal-height="modalHeight"
              :is-save="isSave"
            />
          </div>

          <b-col md="4">
            <b-form-file
              v-model="file"
              :state="Boolean(file)"
              placeholder="Unterschriebene Vollmacht selektieren..."
              drop-placeholder="Datei hier ablegen..."
            />
          </b-col>
          <b-col md="2">
            <div class="mb-1 text-right">
              <b-button
                variant="primary"
                class="align-right"
                :disabled="file === null"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="uploadVollmacht"
              >
                Hochladen
              </b-button>
            </div>
          </b-col>
          <b-col md="2">
            <div class="mb-1 text-right">
              <b-button
                variant="primary"
                class="align-right"
                :disabled="v === null || v.size === 0"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="downloadVollmacht"
              >
                Herunterladen
              </b-button>
            </div>
          </b-col>
        </b-row>

      </tab-content>

      <tab-content
        v-if="true"
        title="Leistungen / Preis"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Mandats-Art - Info: Verrechnung der Leistung erfolgt im Folgemonat der Aktivierung"
              label-for="art"
            >
              <v-select
                v-model="data.Art"
                :disabled="true"
                :options="art"
                :reduce="val => val.value"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="showPrice" md="3" class="text-right">
            <b-form-group
              label="Preis inkl. MwSt"
              label-for="preis"
            >
              <b-form-input v-if="showPrice" id="preisArt"
                v-model="data.PreisArt"
                class="text-right"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="3"
            class="text-center"
          >
            <b-form-group
              label="Aktiviert"
              label-for="aktiviert"
            >
              <b-form-input
                v-model="data.UsedArt"
                class="text-center"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group
              label="Mit Kind(ern)"
              label-for="mitKind"
            >
              <b-form-checkbox
                v-model="data.MitKind"
                :disabled="data.UsedKind!==null || data.Art===2"
                class="custom-control-primary"
                name="check-button"
                switch
                @change="setPreisKind"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="showPrice"
            md="3"
            class="text-right"
          >
            <b-form-group
              label=""
              label-for="preis"
            >
              <b-form-input
                v-model="data.PreisKind"
                class="text-right"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="3"
            class="text-center"
          >
            <b-form-input
              v-model="data.UsedKind"
              class="text-center"
              :disabled="true"
            />
          </b-col>
        </b-row>

        <b-row v-show="data.Art === 1">
          <b-col md="6">
            <b-form-group
              label="Mit Vorsorgeausgleich"
              label-for="mitVA"
            >
              <b-form-checkbox
                v-model="data.MitVA"
                :disabled="data.UsedVA!==null || data.Art!==1"
                class="custom-control-primary"
                name="check-button"
                switch
                @change="setPreisVA"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="showPrice"
            md="3"
            class="text-right"
          >
            <b-form-group
              label=""
              label-for="preis"
            >
              <b-form-input
                v-model="data.PreisVA"
                class="text-right"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="3"
            class="text-center"
          >
            <b-form-input
              v-model="data.UsedVA"
              class="text-center"
              :disabled="true"
            />
          </b-col>
        </b-row>

        <b-row v-show="data.Art !== 2">
          <b-col md="6">
            <b-form-group
              label="Mit Güterrechtlicher Auseinandersetzung"
              label-for="mitGA"
            >
              <b-form-checkbox
                v-model="data.MitGA"
                :disabled="data.UsedGA!==null || data.Art===2"
                class="custom-control-primary"
                name="check-button"
                switch
                @change="setPreisGA"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="showPrice"
            md="3"
            class="text-right"
          >
            <b-form-group
              label=""
              label-for="preis"
            >
              <b-form-input
                v-model="data.PreisGA"
                class="text-right"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="3"
            class="text-center"
          >
            <b-form-input
              v-model="data.UsedGA"
              class="text-center"
              :disabled="true"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group
              label="Mit Korrespondenz, Protokoll und Honorarabrechnung, Kalender (Justitia 4.0)"
              label-for="mitKPH"
            >
              <b-form-checkbox
                v-model="data.MitErweiterung"
                :disabled="data.UsedErweiterung !== null || data.MitZugriffF === 1 || data.MitZugriffM === 1"
                class="custom-control-primary"
                name="check-button"
                switch
                @change="setPreisKPH"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="showPrice"
            md="3"
            class="text-right"
          >
            <b-form-group
              label=""
              label-for="preis"
            >
              <b-form-input
                v-model="data.PreisErweiterung"
                class="text-right"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="3"
            class="text-center"
          >
            <b-form-input
              v-model="data.UsedErweiterung"
              class="text-center"
              :disabled="true"
            />
          </b-col>

          <!-- <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Anmerkung
            </h5>
            <small class="text-muted">Die festgelegten Leistungen steuern den Preis, die Verrechnung erfolgt im Folgemonat der Leistungserbringung.</small>
          </b-col> -->

        </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BFormTextarea, BButton, BFormFile, BOverlay,
} from 'bootstrap-vue'
import { required, email, integer } from '@validations'
import { German } from 'flatpickr/dist/l10n/de'
import { French } from 'flatpickr/dist/l10n/fr'
import dos from '@/service/dossier'
import ben from '@/service/benutzer'
import man from '@/service/mandant'
import kon from '@/service/global'
import brief from '@/service/korrespondenz'
import wordEditor from '../../compo/WordEditor.vue'

export default {
  components: {
    ValidationProvider, ValidationObserver, FormWizard, TabContent, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, flatPickr, vSelect, BFormTextarea, BButton, BFormFile, wordEditor, BOverlay,
  },
  data() {
    const anrede = [
      { value: 'frau', label: this.$g.user.Sprache === 'fr' ? 'Madame' : 'Frau' },
      { value: 'herr', label: this.$g.user.Sprache === 'fr' ? 'Monsieur' : 'Herr' },
    ]

    const art = [
      { label: 'Trennung', value: 0 },
      { label: 'Scheidung', value: 1 },
      { label: 'Elternvereinbarung', value: 2 },
    ]

    const gueterstand = [
      { label: 'Errungenschaftsbeteiligung', value: 'errungenschaft' },
      { label: 'Gütertrennung', value: 'trennung' },
      { label: 'Gütergemeinschaft', value: 'gemeinschaft' },
    ]

    const benutzer = []

    // const perfectScrollbarSettings = {
    //   maxScrollbarLength: 100,
    //   suppressScrollX: true,
    // }
    return {
      data: null,
      isWatchActive: false,
      anrede,
      art,
      k: null,
      b: null,
      v: null,
      modalHeight: window.innerHeight - 300,
      docIn: null,
      file: null,
      isRun: false,
      isSave: false,
      benutzer,
      gueterstand,
      required,
      email,
      integer,
      showPrice: true,
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'j M, Y',
        altInput: true,
        // dateFormat: 'd.m.Y',
        locale: this.$g.user.Sprache === 'fr' ? French : German,
      },
    }
  },
  async mounted() {
    // console.log('mounted...')
    window.checkSave = null
    this.isWatchActive = false
    window.saveFormData = this.formSubmitted
    window.isFormValid = true

    const rk = await kon.getKonfig(this.$g.user.BenID)
    if (rk.data.length === 1) this.k = rk.data[0]

    const rb = await brief.getDossierBrief(6, this.$g.user.Sprache)
    if (rb != null && rb.data !== null) this.b = rb.data[0]

    this.$root.$refs.MandatInfo = this // Register to reload data when changing dossier in Mandant-view...
    window.checkSave = null
    this.isWatchAtive = false
    const r = await dos.getDossierKosten(this.$root.currDosID, this.$g.user.BenSprache)
    if (r.data.length > 0) this.data = r.data[0]
    if (r.data.length > 0) this.data.PreisArt = this.k.PreisArt

    const m = await man.getMandantBenutzerSel(this.$g.user.MandantID)
    this.benutzer = m.data !== null ? m.data : []

    const rv = await dos.getDossierVollmacht(this.$root.currDosID)
    this.v = rv !== null ? rv.data : null

    await this.$nextTick() // Trick to get $refs not to be undefined...
    if (this.data !== null && this.$refs.wizard !== null) this.$refs.wizard.activateAll()
  },
  watch: {
    data: {
      async handler() {
        if (this.isWatchActive) { // handler is called automatically 1st time, thus isWatchActive is only set in else once...
          window.checkSave = this.$root.checkSave
          // console.log('checkSave set')
        } else this.isWatchActive = true
        const isHonObValid = this.$refs.klientHonorarRules != null ? await this.$refs.klientHonorarRules.validate() : true
        const isParObValid = this.$refs.klientPartnerRules != null ? await this.$refs.klientPartnerRules.validate() : true
        window.isFormValid = isHonObValid && isParObValid
        // console.log(window.isFormValid)
      },
      deep: true,
    },
  },
  methods: {
    async refetch() { // Called from Mandat selection..
      // console.log('refetching...')
      const r = await dos.getDossierKosten(this.$root.currDosID, this.$g.user.BenSprache)
      if (r.data.length > 0) {
        this.data = r.data[0]
        this.$root.VNameFrau = this.data.VornameF
        this.$root.VNameMann = this.data.VornameM
      }
      await this.$nextTick() // Trick to get $refs not to be undefined...
      if (this.$refs.wizard !== null) this.$refs.wizard.activateAll()
      if (this.$refs.wizard !== null) this.$refs.wizard.changeTab(0, 0)
      window.checkSave = null
      this.isWatchActive = false
    },
    async initializeNewMandat() {
      const r = await dos.getDossierKosten(3383, this.$g.user.BenSprache)
      if (r.data.length > 0) this.data = r.data[0]
      this.data.DosID = 0
      this.data.BenID = this.$g.user.BenID
      this.data.AnredeF = 'frau'
      this.data.VornameF = ''
      this.data.NameF = ''
      this.data.PlzF = ''
      this.data.OrtF = ''
      this.data.StrasseNrF = ''
      this.data.EMailF = ''
      this.data.TelefonF = ''
      this.data.ZugriffF = false
      this.data.AnredeM = 'herr'
      this.data.VornameM = ''
      this.data.NameM = ''
      this.data.PlzM = ''
      this.data.OrtM = ''
      this.data.StrasseNrM = ''
      this.data.EMailM = ''
      this.data.TelefonM = ''
      this.data.ZugriffM = false
      this.data.Art = 0
      this.data.Gueterstand = 'errungenschaft'
      this.data.HatVertrag = false
      this.data.Bemerkung = ''
      this.data.PreisArt = this.k.PreisArt
      this.data.MitKind = false
      this.data.MitGA = false
      this.data.MitVA = false
      this.data.MitErweiterung = false
      this.data.UsedArt = null
      this.data.UsedKind = null
      this.data.UsedGA = null
      this.data.UsedErweiterung = null
      this.data.RechnungKlient = true

      this.setMandat()
    },
    setMitZugriff() {
      this.data.MitErweiterung = this.data.UsedErweiterung !== null || (this.data.MitZugriffF === 1 || this.data.MitZugriffM === 1)
    },
    setMandat() {
      this.data.PreisArt = this.k.PreisArt
      this.data.MitVA = this.data.Art === 1 || this.data.UsedVA !== null // Scheidung
      this.data.PreisVA = this.data.MitVA ? this.k.PreisVA : 0
      this.data.MitGA = this.data.Art === 1 || this.data.UsedGA !== null // Scheidung
      this.data.PreisGA = this.data.MitGA ? this.k.PreisGA : 0
      this.data.Gueterstand = (this.data.Art === 0 || this.data.Art === 1) ? 'errungenschaft' : this.data.Art === 2 ? 'trennung' : '' // eslint-disable-line
      this.data.MitKind = this.data.Art === 2 || this.data.UsedKind !== null // Betreuung, bzw. Elternvereinbarung
      this.data.PreisKind = (this.data.MitKind || this.data.UsedKind !== null) ? this.k.PreisKind : 0
      this.data.MitErweiterung = true
      this.data.PreisErweiterung = this.data.MitErweiterung ? this.k.PreisErweitert : 0

      this.$root.currDosBez = 'Neues Mandat...'
    },
    setPreisKind() {
      this.data.PreisKind = this.data.MitKind ? this.k.PreisKind : 0
    },
    setPreisVA() {
      this.data.PreisVA = this.data.MitVA ? this.k.PreisVA : 0
    },
    setPreisGA() {
      this.data.PreisGA = this.data.MitGA ? this.k.PreisGA : 0
    },
    setPreisKPH() {
      this.data.PreisErweiterung = this.data.MitErweiterung ? this.k.PreisErweitert : 0
    },
    async getVollmacht() {
      this.isRun = true
      const r = await brief.getDossierVollmacht(this.data.VornameF, this.data.NameF, this.data.Art, this.$g.user.MandantID, this.$g.user.Vorname, this.$g.user.Name, this.$g.user.BenSprache)
      if (r != null) {
        this.docIn = r.data
        this.$refs.we.show()
      }
      this.isRun = false
    },
    async uploadVollmacht() {
      if (this.file !== null) await dos.postDossierVollmacht(this.file, this.$root.currDosID)
      this.$root.showToast('Vollmacht speichern', 'Vollmacht wurde erfolgreich gespeichert!')
    },
    async downloadVollmacht() {
      const v = await dos.getDossierVollmacht(this.$root.currDosID)
      const fileURL = window.URL.createObjectURL(v.data)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', 'Vollmacht.pdf')
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    async formSubmitted() {
      // console.log('formSubmitted')
      this.$root.VNameFrau = this.data.VornameF
      this.$root.VNameMann = this.data.VornameM

      const r = await dos.putDossierKosten(this.data, this.$g.user.BenID, this.$g.user.MandantID, this.$g.user.BenSprache)
      if (this.data.DosID === 0 && r.data !== null) {
        // console.log(r.data[0])
        this.$root.currDosID = r.data[0]
        this.refetch()
        this.$root.$refs.Mandat.refetchAfterAdd()
      }

      this.$root.currDosArt = this.data.Art
      this.$root.currDosBez = (this.$root.currDosArt === 0 ? 'Trennung ' : this.$root.currDosArt === 1 ? 'Scheidung ' : 'Elternvereinbarung ') + this.data.NameF + ' /c ' + this.data.NameM // eslint-disable-line

      if (this.data.MitZugriffF) {
        const bf = await ben.BenutzerInsert(this.$g.user.MandantID, this.$root.currDosID, this.data.EMailF, this.$g.user.BenSprache, this.data.VornameF, this.data.NameF, 'klient') // eslint-disable-line
      }
      if (this.data.MitZugriffM) {
        const bm = await ben.BenutzerInsert(this.$g.user.MandantID, this.$root.currDosID, this.data.EMailM, this.$g.user.BenSprache, this.data.VornameM, this.data.NameM, 'klient') // eslint-disable-line
      }
      const a = await ben.getBenutzerAbilities(this.$g.user.role, this.$root.currDosID)
      if (a.data !== null) {
        const ud = JSON.parse(localStorage.getItem('userData'))
        ud.Ability = a.data
        localStorage.setItem('userData', JSON.stringify(ud))
        this.$ability.update(JSON.parse(ud.Ability))
      }

      window.checkSave = null
      this.isWatchActive = false

      this.$root.showToast('Speichern', 'Mandatdetails Leistungen und Preis erfolgreich gespeichert!')
    },
    validationKlientHonorar() {
      return new Promise((resolve, reject) => {
        this.$refs.klientHonorarRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationKlientPartner() {
      return new Promise((resolve, reject) => {
        this.$refs.klientPartnerRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
